<template>
    <v-row dense no-gutters>
        <v-col>
            <v-card class="pa-5" tile>
                <v-row align="center" dense justify="start" no-gutters>
                    <v-btn class="me-2" color="primary" outlined tile
                           @click="addQuestion(QuestionType.check)">
                        Query/Instruction
                    </v-btn>
                    or
                    <v-btn class="ms-2" color="primary" outlined tile
                           @click="addQuestion(QuestionType.section)">
                        Add section
                    </v-btn>
                </v-row>
            </v-card>
        </v-col>
        <kurcc-form-spacer/>
    </v-row>
</template>

<script>
import {QuestionType} from '@/modules/forms/enums/question-type';
import {INSERT_INSPECTION_FORM_QUESTION} from "@/modules/app/store/mutation-types";

export default {
    name: "KurccEmptyQuestionPageActionBar",
    components: {
        KurccFormSpacer: () => import('@/modules/forms/components/KurccFormSpacer')
    },
    props: {
        pageIndex: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            QuestionType
        }
    },
    methods: {
        addQuestion(questionType) {
            this.$store.commit(INSERT_INSPECTION_FORM_QUESTION, {
                pageIndex: this.pageIndex,
                insertIndex: 0,
                questionType
            })
        }
    }
}
</script>
